.align-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.header {
    background-color: #F7F5F5;
    font-size: 12px;
}