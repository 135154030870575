@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans-BoldItalic.woff2') format('woff2'),
        url('IBMPlexSans-BoldItalic.woff') format('woff'),
        url('IBMPlexSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans-ExtraLight.woff2') format('woff2'),
        url('IBMPlexSans-ExtraLight.woff') format('woff'),
        url('IBMPlexSans-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans-Bold.woff2') format('woff2'),
        url('IBMPlexSans-Bold.woff') format('woff'),
        url('IBMPlexSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans-ExtraLightItalic.woff2') format('woff2'),
        url('IBMPlexSans-ExtraLightItalic.woff') format('woff'),
        url('IBMPlexSans-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans-Italic.woff2') format('woff2'),
        url('IBMPlexSans-Italic.woff') format('woff'),
        url('IBMPlexSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans-Medium.woff2') format('woff2'),
        url('IBMPlexSans-Medium.woff') format('woff'),
        url('IBMPlexSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans-LightItalic.woff2') format('woff2'),
        url('IBMPlexSans-LightItalic.woff') format('woff'),
        url('IBMPlexSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans-SemiBoldItalic.woff2') format('woff2'),
        url('IBMPlexSans-SemiBoldItalic.woff') format('woff'),
        url('IBMPlexSans-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans-Light.woff2') format('woff2'),
        url('IBMPlexSans-Light.woff') format('woff'),
        url('IBMPlexSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans-SemiBold.woff2') format('woff2'),
        url('IBMPlexSans-SemiBold.woff') format('woff'),
        url('IBMPlexSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans-Regular.woff2') format('woff2'),
        url('IBMPlexSans-Regular.woff') format('woff'),
        url('IBMPlexSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans-MediumItalic.woff2') format('woff2'),
        url('IBMPlexSans-MediumItalic.woff') format('woff'),
        url('IBMPlexSans-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans-Thin.woff2') format('woff2'),
        url('IBMPlexSans-Thin.woff') format('woff'),
        url('IBMPlexSans-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans-ThinItalic.woff2') format('woff2'),
        url('IBMPlexSans-ThinItalic.woff') format('woff'),
        url('IBMPlexSans-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

